import React from 'react';
import Layout from '../components/layout';

const SecondPage = () => (
  <Layout>
    Sample page 2
  </Layout>
);

export default SecondPage;
